import { useContext, useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import { format } from 'date-fns'
import ReactDOM from 'react-dom'

import { Grid, Hidden, Typography, useMediaQuery } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import HeaderContext from 'context/headerContext'
import useResponsive from 'hooks/useResponsive'
import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import { percentageFormatter } from 'utils/formatters/percentageFormatters'
import baseStyles from 'utils/UI/BaseStyles'
import CustomModeButtonGroup from 'utils/UI/Buttons/CustomModeButtonGroup'
import Search from 'utils/UI/Inputs/Search'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { positionsSummaryRequest } from 'axios/requests/summary'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

const FirstLinearProgress = withStyles((theme) => ({
  root: {
    margin: 3,
    width: '100%',
    minWidth: 180,
    borderRadius: 10,
    height: 10
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.light
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}))(LinearProgress)

const SecondLinearProgress = withStyles((theme) => ({
  root: {
    margin: 3,
    width: '100%',
    minWidth: 180,
    borderRadius: 10,
    height: 10
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.light
  },
  bar: {
    borderRadius: 3,
    backgroundColor: theme.palette.info.main
  }
}))(LinearProgress)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableContainer-root': {
      minWidth: 400,
      '& .MuiTable-root': {
        tableLayout: 'auto'
      }
    }
  },
  assetClassName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0
  },
  graphSelectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    marginBottom: theme.spacing(0)
  },
  graphDataType: {
    height: 'auto',
    width: '100%'
  },
  progressBarCol: {
    width: 500,
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  barContainer: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      width: 32,
      lineHeight: 1
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  }
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export default function PositionsSummary() {
  const { t } = useTranslation(['dashboard', 'asset_classes'])
  const columns = useMemo(
    () => [
      {
        title: t('Clase de Activo'),
        id: 'name',
        align: 'left'
      },
      {
        title: t('Objetivo'),
        id: 'target_weight',
        align: 'right'
      },
      {
        title: t('Posición'),
        id: 'weight',
        align: 'right'
      },
      {
        title: t('Valor'),
        id: 'value',
        align: 'right'
      },
      {
        title: t('Retorno'),
        id: 'return_value',
        align: 'right'
      },
      {
        title: t('Actual y Objetivo'),
        id: 'weight-and-target_weight',
        align: 'center'
      }
    ],
    [t]
  )

  const defaultModeList = useMemo(
    () => [
      { key: 'root-class', label: t('Clase de Activo') },
      { key: 'macro_asset', label: t('Macro Activo') },
      { key: 'currency', label: t('Moneda') },
      { key: 'institution', label: t('Institución') },
      { key: 'asset', label: t('Activo') }
    ],
    [t]
  )

  const classes = useStyles()
  const baseClasses = baseStyles()
  const [search, setSearch] = useState('')
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [aggregate, setMode] = useState('root-class')
  const [tableData, setTableData] = useState(null)
  const { isMobile, isDesktop } = useResponsive()
  const [dataObjective, setDataObjective] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [hideSortIcon] = useState(true)
  const [localColumns, setLocalColumns] = useState(columns)
  const matchesMobile = useMediaQuery('(max-width:465px)')
  const [loading, setLoading] = useState(true)
  const maxColumnWidth = Math.floor(350 / columns.length)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await positionsSummaryRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency,
          portfolio_id: currentPortfolio.id,
          aggregate
        })
        return result.data
      } catch (err) {
        errorNotification('generalError')
        setLoading(false)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.positionsSummaryData) {
          setTableData(data.positionsSummaryData)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio, aggregate, startDate, endDate])

  useEffect(() => {
    if (!tableData) return
    bindTooltips()
  }, [tableData])

  const data = useMemo(() => {
    let count = 0
    if (search && aggregate === 'asset') {
      return tableData.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase())
      })
    } else {
      if (tableData) {
        setDataObjective(false)
        tableData.map((column) => {
          count += parseFloat(column.target_weight)
        })
        if (count > 0) {
          setDataObjective(true)
        }
      }

      return tableData
    }
  }, [tableData, search, aggregate])

  const bindTooltips = () => {
    setTimeout(() => {
      const assetsElements = document.getElementsByClassName('assetName')
      var fun = function () {
        if (this.offsetWidth < this.scrollWidth) {
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(
            <Tooltip title={this.dataset.asset}>
              <span>{this.dataset.asset}</span>
            </Tooltip>,
            this
          )
        } else {
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(this.dataset.asset, this)
        }
      }
      for (var i = 0; i < assetsElements.length; i++) {
        assetsElements[i].addEventListener('mouseover', fun, false)
      }
    }, 500)
  }

  const findMaxNum = (position, target) => {
    return Math.max(...[...Object.values(position), ...Object.values(target)])
  }

  const adjust = (toAdjust, maxNum) => {
    return Object.entries(toAdjust).reduce((acc, [key, value]) => {
      acc[key] = (value / maxNum) * 100
      return acc
    }, {})
  }

  const adjustData = useMemo(() => {
    if (!tableData) return {}
    let positionValues = {}
    let targetValues = {}
    tableData.forEach((row) => {
      positionValues[row.name] = row.weight
      targetValues[row.name] = row.target_weight
    })
    const maxNum = findMaxNum(positionValues, targetValues)

    positionValues = adjust(positionValues, maxNum)
    targetValues = adjust(targetValues, maxNum)

    return {
      positionValues,
      targetValues
    }
  }, [tableData])

  useEffect(() => {
    const newColumns = columns.map((col, i) => {
      if (i === 0) {
        return {
          ...col,
          title:
            aggregate === 'root-class' || aggregate === 'macro_asset'
              ? t('Clase de Activo')
              : defaultModeList.find((item) => item.key === aggregate).label
        }
      }
      return col
    })
    setLocalColumns(newColumns)
  }, [aggregate, columns, defaultModeList, t])

  const renderModeButtonGroup = (
    <CustomModeButtonGroup
      modeList={defaultModeList}
      currentValue={aggregate}
      onClickHandler={setMode}
    />
  )

  const renderModeButtonGroupMobile = (
    <>
      {matchesMobile ? (
        <Grid container>
          <CustomModeButtonGroup
            modeList={defaultModeList}
            currentValue={aggregate}
            onClickHandler={setMode}
          />
          {aggregate === 'asset' ? (
            <Search
              placeholder="Buscar..."
              width={160}
              onChange={setSearch}
              value={search}
            />
          ) : null}
        </Grid>
      ) : (
        <>
          {aggregate === 'asset' ? (
            <Search
              placeholder="Buscar..."
              width={160}
              onChange={setSearch}
              value={search}
            />
          ) : null}
          <CustomModeButtonGroup
            modeList={defaultModeList}
            currentValue={aggregate}
            onClickHandler={setMode}
          />
        </>
      )}
    </>
  )

  return (
    <CardWrapper
      title={t('Posiciones')}
      action={isMobile ? renderModeButtonGroupMobile : null}
      shadow={false}
    >
      <Hidden smDown>
        <div className={classes.graphSelectorsContainer}>
          <div className={classes.graphDataType}>{renderModeButtonGroup}</div>
          {aggregate === 'asset' ? (
            <Search
              placeholder="Buscar..."
              width={200}
              onChange={setSearch}
              value={search}
            />
          ) : null}
        </div>
      </Hidden>
      {(loading || !tableData) && <Loading />}
      {tableData && (
        <div className={classes.root}>
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {localColumns.map((column, index) => {
                    const { align = 'left' } = column
                    if (index === 0) {
                      return (
                        <StyledTableCell
                          key={column.id}
                          style={{
                            width: 'max-content',
                            minWidth: 120
                          }}
                          align={align}
                          direction={orderBy === column.id ? order : 'asc'}
                        >
                          <TableSortLabel
                            className={hideSortIcon ? classes.hideSortIcon : ''}
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={(event) =>
                              handleRequestSort(event, column.id)
                            }
                          >
                            {column.title}
                            {orderBy === column.id ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </StyledTableCell>
                      )
                    } else if (index === columns.length - 1) {
                      if (isDesktop) {
                        return (
                          <StyledTableCell
                            style={{ maxWidth: 200 }}
                            align={align}
                            key={column.id}
                          >
                            {column.title}
                          </StyledTableCell>
                        )
                      }
                    } else if (index === 1) {
                      if (dataObjective) {
                        return (
                          <StyledTableCell
                            width={50}
                            align={align}
                            key={column.id}
                          >
                            <TableSortLabel
                              className={
                                hideSortIcon ? classes.hideSortIcon : ''
                              }
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : 'asc'}
                              onClick={(event) =>
                                handleRequestSort(event, column.id)
                              }
                            >
                              {column.title}
                              {orderBy === column.id ? (
                                <span className={classes.visuallyHidden}>
                                  {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                                </span>
                              ) : null}
                            </TableSortLabel>
                          </StyledTableCell>
                        )
                      }
                    } else {
                      return (
                        <StyledTableCell
                          width={index === 2 ? 80 : column.width}
                          align={align}
                          key={column.id}
                        >
                          <TableSortLabel
                            className={hideSortIcon ? classes.hideSortIcon : ''}
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={(event) =>
                              handleRequestSort(event, column.id)
                            }
                          >
                            {column.title}
                            {orderBy === column.id ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </StyledTableCell>
                      )
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.sort(getComparator(order, orderBy)).map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <StyledTableCell
                        style={{ maxWidth: 'max-content', minWidth: 120 }}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        {row.name.length > maxColumnWidth ? (
                          <Tooltip title={t(row.name, { ns: 'asset_classes' })}>
                            <span>{t(row.name, { ns: 'asset_classes' })}</span>
                          </Tooltip>
                        ) : (
                          <p
                            data-asset={t(row.name, { ns: 'asset_classes' })}
                            className={clsx(
                              'assetName',
                              classes.assetClassName
                            )}
                          >
                            {t(row.name, { ns: 'asset_classes' })}
                          </p>
                        )}
                      </StyledTableCell>

                      {dataObjective && (
                        <StyledTableCell align="right">
                          {percentageFormatter(row.target_weight)}
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="right">
                        {percentageFormatter(row.weight)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Tooltip
                          title={
                            aggregate === 'asset' ? (
                              <div>
                                valor cuota: {row.valor_cuota} <br /> numero de
                                cuotas: {row.num_cuotas}
                              </div>
                            ) : (
                              ''
                            )
                          }
                          arrow
                          placement="right"
                        >
                          <div> {bigNumberFormatter(row.value)}</div>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <span
                          className={clsx({
                            [baseClasses.errorColor]: row.return_value < 0,
                            [baseClasses.successColor]: row.return_value > 0
                          })}
                        >
                          {percentageFormatter(row.return_value * 100)}
                        </span>
                      </StyledTableCell>
                      {isDesktop && (
                        <StyledTableCell
                          align="center"
                          className={classes.progressBarCol}
                        >
                          <div className={classes.barContainer}>
                            <Tooltip
                              title={`${percentageFormatter(row.weight)}%`}
                              arrow
                              placement="top"
                            >
                              <FirstLinearProgress
                                variant="determinate"
                                value={adjustData.positionValues[row.name]}
                              />
                            </Tooltip>
                            <Typography variant="caption" color="textSecondary">
                              {percentageFormatter(row.weight)}
                            </Typography>
                          </div>
                          {row.target_weight !== 0 && (
                            <div className={classes.barContainer}>
                              <Tooltip
                                title={`${row.target_weight}%`}
                                arrow
                                placement="top"
                              >
                                <SecondLinearProgress
                                  variant="determinate"
                                  value={adjustData.targetValues[row.name]}
                                />
                              </Tooltip>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {percentageFormatter(row.target_weight)}
                              </Typography>
                            </div>
                          )}
                        </StyledTableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </div>
      )}
    </CardWrapper>
  )
}
